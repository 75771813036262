@import '../../custom';

.ivpn-header {
  margin-top: -6rem;
  padding-top: 6rem;
  padding-bottom: 15rem;
}

.content-container {
  margin-top: -11rem;
}